import styled from "styled-components";
import SearchBox from "./search-box";

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  .SearchInput {
    outline: none;
    background-color: #000;
    font-size: 1em;
    transition: 100ms;
    border-radius: 2px;
    color: ${({ theme }) => theme.foreground};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    height: 1em;
    width: 100%;
    background: ${({ theme }) => theme.background};
    cursor: text;
    margin-left: -1em;
    padding-left: 2em;
    padding: 1.3em;
    border: 1px solid #ccc;
  }
  .SearchIcon {
    width: 2.75em;
    margin: 0.3em;
    color: ${({ theme }) => theme.foreground};
    pointer-events: none;
    background-color: #fff;
    color: #247489;
    padding: 0.6em;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 2.75em;
  }
`;
