import React from "react";
import styled from "@emotion/styled";
import logoImg from "../assets/logoExpLight.png";

const Wrapper = styled.div({
  display: "flex",
  fontSize: 24,
  paddingRight: "calc(100% - 486px)", //576px with auth
});

const imgStyle = {
  marginBottom: 0,
};

export default function Logo() {
  return (
    <Wrapper>
      <a href="/">
        <img class={imgStyle} src={logoImg} alt={"logo"} width="180px" />
      </a>
    </Wrapper>
  );
}
