import React from "react";
import { Helmet } from "react-helmet";
import favicon from "./favicon.ico";

export default function SEO({ title, description, siteName }) {
  return (
    <Helmet>
      <link rel="icon" type="image/x-icon" href={favicon} />
      <title>{title}</title>
    </Helmet>
  );
}
