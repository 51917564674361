import styled from "@emotion/styled";

const FlexWrapper = styled.div((props) => ({
  display: "flex",
  minHeight: "100vh",
  margin: "0 auto",
  minWidth: 600,
  backgroundColor: props.theme.backgroundColor,
}));

export default FlexWrapper;
