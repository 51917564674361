exports.colors = {
  primary: "#247489",
  primaryLight: "#91B9C4",
  secondary: "#000000",
  tertiary: "#949494",
  tertiaryLight: "#AFAFAF",
  divider: "#DEE2E7",
  background: "#DDDDDD",
  background2: "#001933",
  backgroundNotice: "#E8C161",
  backgroundColor: "#F9F9F9",
  sideBgColor: "#F0F0F0",
  text1: "#000000",
  text2: "#A8A8A8",
  text3: "#808080",
  text4: "#A8A8A8",
  textNotice: "#AD7900",
  warning: "#AD7900",
  shadow: "#000000",
  highlight: "#E8C161",
  highlight2: "#FFAE00",
  highlight3: "#FFDC96",
  codeComment: "#AD7900",
  hoverOpacity: 0.8,
};
