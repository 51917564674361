import auth0 from "auth0-js";
import { navigate } from "gatsby";

const isBrowser = typeof window !== "undefined";

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN ?? "cloudsparklabs.us.auth0.com",
      clientID:
        process.env.AUTH0_CLIENTID ?? "heuf6o3omdvHGTZnauDFuzUvzqYrQWlf",
      //redirectUri: process.env.AUTH0_CALLBACK ?? 'http://peopleops.ngrok.io/auth/callback',
      // redirectUri: process.env.AUTH0_CALLBACK ?? 'https://green-tree-04772e910.azurestaticapps.net/auth/callback',
      redirectUri:
        process.env.AUTH0_CALLBACK ?? "https://help.employeexp.com/callback",
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {};

let user = {};

export const isAuthenticated = () => {
  return true;
  /* temporarily removed auth
  if (isBrowser) {
    // don't use authentication for local development:
    if (window.location.hostname === "localhost") {
      return true;
    }
    return localStorage.getItem("isLoggedIn") === "true";
  }
  */
};

export const login = () => {
  if (isBrowser) {
    auth.authorize();
  }
};

const setSession = (cb = () => {}) => (err, authResult) => {
  if (err) {
    navigate("/");
    cb();
    return;
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    user = authResult.idTokenPayload;
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("accessToken", authResult.accessToken);
    localStorage.setItem("idToken", authResult.idToken);
    localStorage.setItem("expiresAt", expiresAt);
    localStorage.setItem("username", user.given_name);
    navigate("/");
    cb();
  }
};

export const handleAuthentication = () => {
  if (isBrowser) auth.parseHash(setSession());
};

export const getProfile = () => {
  return { name: localStorage.getItem("username") || "" };
  // return user
};

export const logout = () => {
  localStorage.setItem("isLoggedIn", false);
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("expiresAt");
  localStorage.removeItem("username");
  auth.logout({ returnTo: process.env.AUTH0_REDIRECT });
};
